export const transition = { type: "spring", duration: 1 };

// GENERAL
export const slideAnimation = (direction, spring=false) => {
  return {
    initial: {
      x: direction === "left" ? -100 : direction === "right" ? 100 : 0,
      y: direction === "up" ? 100 : direction === "down" ? -100 : 0,
      opacity: 0,
      transition: !spring?null:{ ...transition, delay: 0.5 },
    },
    animate: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: !spring?null:{ ...transition, delay: 0 },
    },
    whileInView: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: !spring?null:{ ...transition},
    },
    exit: {
      opacity:0
    },
    transition: {
      type: "spring"
    },
  };
};

export const fadeAnimation = {
  initial: {
    opacity: 0,
    transition: { ...transition, delay: 0.5 },
  },
  animate: {
    opacity: 1,
    transition: { ...transition, delay: 0 },
  },
  exit: {
    opacity: 0,
    transition: { ...transition, delay: 0 },
  },
};

export const springAnimation = {
  initial: { scale: 0 },
  whileInView: { scale: 1},
  animate: { scale: 1},
  exit: { scale: 0 },
  transition: {
    type: "spring"
  },
};

// ISLAND / COMPONENT SPECIFIC
export const navAnimation = {
    initial: {x:-500},
    animate: {x:0},
    transition: {
        type: "slide",
        damping: 5,
        stiffness: 40,
        restDelta: 0,
        duration: 1,
    }
}