import React from 'react';
import './index.css';
import Marquee from '../../components/Marquee';
import Pointer from '../../components/Pointer';
import { motion } from 'framer-motion';
import { slideAnimation,springAnimation } from '../../util/JsAnimations';

export default function Landing() {
    return (
        <section id="landing">
            <div id="overlay">
                <Marquee phrase="Dreams Made Real." direction="vertical" repeat={1} />
                <motion.img id='BG_Element' src="/assets/BG_Element.svg" alt="logo" initial={springAnimation.initial} whileInView={springAnimation.whileInView} animate={springAnimation.animate} />
            </div>
            <div id="content">
                <div id="content-head">
                    <img src="/assets/emblem.jpg" alt="emblem" />
                    <div id="content-title">
                        <motion.h1 initial={slideAnimation("left").initial} animate={slideAnimation("left").animate} whileInView={slideAnimation("left").whileInView}>The World Of Design</motion.h1>
                        <motion.h2 initial={slideAnimation("left").initial} animate={slideAnimation("left").animate} whileInView={slideAnimation("left").whileInView}>By Sherigan Vidal Naidoo</motion.h2>
                    </div>
                </div>
                <Pointer size={80} anchor="about" />
            </div>
        </section>
    )
}
