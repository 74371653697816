import React from 'react';
import './index.css';

function Footer(props) {

    // Example: Project Atomic
    if(props.copyrightProperty === undefined || props.copyrightProperty === null || props.copyrightProperty === '') throw new Error('Copyright property is required');
    // Example: 2024
    if(props.copyrightYear === undefined || props.copyrightYear === null || props.copyrightYear === '') props.copyrightYear = new Date().getFullYear();
    // EExample: Made with Pure Awesomeness
    if(props.madeWithSlogan === undefined || props.madeWithSlogan === null || props.madeWithSlogan === '') throw new Error('Made with slogan is required');

    return (
        <footer>
            <div id="footer--outer">
                <div id="footer--inner">
                    <div className="footer">
                        <div>© {props.copyrightYear} {props.copyrightProperty}. All rights reserved.</div>
                        <div>Made with <span className='font-playwriteBEWAL'>{props.madeWithSlogan}</span> by <a className='font-playwriteBEWAL' href="https://theshekharmaharaj.com">Shekhar Maharaj</a></div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer