import React from 'react'
import './index.css';
import ServiceItem from '../../components/ServiceItem';
import { motion } from 'framer-motion';
import { slideAnimation,springAnimation } from '../../util/JsAnimations';

function Services() {

    const framerMotion = motion;

    return (
        <section id="services">
            <div id="top">
                <div id="top--left">
                    <motion.h1 initial={slideAnimation("right").initial} animate={slideAnimation("right").animate} whileInView={slideAnimation("right").whileInView} exit={slideAnimation("right").exit}><span>Services</span><span>Offered</span></motion.h1>
                </div>
                <motion.div id="top--right" initial={slideAnimation("right").initial} animate={slideAnimation("right").animate} whileInView={slideAnimation("right").whileInView} exit={slideAnimation("right").exit}>
                    Select any of my high quality services to help build your product.
                </motion.div>
            </div>
            <div id="services-list">
                <ServiceItem motion={framerMotion} title={"Logo Designs"} link="contact" iconPath={'/assets/icons/logodesigns.svg'} />
                <ServiceItem motion={framerMotion} title={"Business Cards"} link="contact" iconPath={'/assets/icons/businesscards.svg'} />
                <ServiceItem motion={framerMotion} title={"Print Setups"} link="contact" iconPath={'/assets/icons/printsetups.svg'} />
                <ServiceItem motion={framerMotion} title={"Custom Designs"} link="contact" iconPath={'/assets/icons/customdesigns.svg'} />
                <ServiceItem motion={framerMotion} title={"Letterheads"} link="contact" iconPath={'/assets/icons/letterheads.svg'} />
                <ServiceItem motion={framerMotion} title={"Photo Editing"} link="contact" iconPath={'/assets/icons/photoediting.svg'} />
                <ServiceItem motion={framerMotion} title={"Magazine Spread"} link="contact" iconPath={'/assets/icons/magazinespread.svg'} />
                <ServiceItem motion={framerMotion} title={"Web Design"} link="https://theshekharmaharaj.com" iconPath={'/assets/icons/webdesign.svg'} collabWithSM={true} />
            </div>
        </section>
    )
}

export default Services