import React, { useState } from 'react';
import './index.css';
import FormInput from '../../components/FormInput';
import FormButton from '../../components/FormButton';
import ContactFormProductSelection from '../../islands/ContactFormProductSelection';
import { slideAnimation } from '../../util/JsAnimations';
import {motion} from 'framer-motion';

function Contact() {

    const [fName,setFName] = useState("");
    const [fLame,setLName] = useState("");
    const [emailAddress,setemailAddress] = useState("");
    const [productList,setProductList] = useState([]);

    const handleSendEvent = (event)=>{
        let sendEmailHref = "mailto:sheriganvidal01@gmail.com?subject=Contact%20Request%20from%20" + fName + "%20" + fLame + "&body=Product%20Selection:%20" + productList.join(",%20") + "%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0";

        console.log(productList);
        console.log(sendEmailHref);
        window.location.href = sendEmailHref;
    };
    return (
        <section id="contact">
            <div id="contact-top">
                <motion.h2 initial={slideAnimation("left").initial} animate={slideAnimation("left").animate} whileInView={slideAnimation("left").whileInView}>Let's Get In Touch</motion.h2>
                <motion.a initial={slideAnimation("left").initial} animate={slideAnimation("left").animate} whileInView={slideAnimation("left").whileInView} href="tel:+2784-622-5256" id="contact--mobile">Mobile: +27 84-622-5256</motion.a>
                <motion.a initial={slideAnimation("left").initial} animate={slideAnimation("left").animate} whileInView={slideAnimation("left").whileInView} href="mailto:sheriganvidal01@gmail.com" id="contact--email">Email: sheriganvidal01@gmail.com</motion.a>
            </div>
            {/* <div id="contact-bottom">
                <span>Request More Info:</span>
                <div id="contact-form">
                    <div id="contact-form--main-details">
                        <FormInput templateAreaName={"fName"} label="FIRST NAME" type="text" onChange={(value) => {setFName(value) }} />
                        <FormInput templateAreaName={"lName"} label="LAST NAME" type="text" onChange={(value) => {setLName(value) }} />
                        <FormInput templateAreaName={"emailAddress"} label="EMAIL" type="email" onChange={(value) => {setemailAddress(value) }} />

                        <FormButton templateAreaName={"sendBtn"} buttonText="Send" uppercase={true} color={'yellow'} onClick={handleSendEvent} />
                    </div>
                    <div id="contact-form--product-details">
                        <ContactFormProductSelection handleSelectionUpdate={(updatedList)=>{setProductList(updatedList)}} />
                    </div>
                </div>
            </div> */}
        </section>
    )
}

export default Contact