import React from "react";
// ADD PAGE IMPORTS HERE
import Landing from "./pages/Landing";
import Services from "./pages/Services";
import ChooseMe from "./pages/ChooseMe";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";

function App() {
  return (
    <>
      <Landing />
      <Services />
      <ChooseMe />
      <Contact />
      <Footer copyrightProperty="The World of Design" copyrightYear="2024" madeWithSlogan="Pure Awesomeness" />
    </>
  );
}

export default App;
