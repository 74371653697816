import React from 'react';
import './index.css';
import { slideAnimation } from '../../util/JsAnimations';
import {motion} from 'framer-motion';

function ChooseMe() {
    return (
        <section id="chooseMe">
            <div id="underlay">
                <motion.div initial={slideAnimation("right").initial} animate={slideAnimation("right").animate} whileInView={slideAnimation("right").whileInView} className='font-holtwoodOneSC' id="chooseMe-banner"> Design Done Right</motion.div>
            </div>
            <div id="main">
                <div id="main-bar">
                    <div>
                        <motion.h2 initial={slideAnimation("left").initial} animate={slideAnimation("left").animate} whileInView={slideAnimation("left").whileInView}>Why choose me?</motion.h2>
                        <motion.p initial={slideAnimation("left").initial} animate={slideAnimation("left").animate} whileInView={slideAnimation("left").whileInView}>
                            Choose the <span>right graphic designer</span>. I bring a blend of skills and experience. I bring an adaption of new age designs with golden age elegance With 2 years of Graphic Design Studies and Experience. I am well-equipped to handle various design tasks. My role as a printer operator has honed my skills in <span>Repro and print setups</span>, freehand drawing, logo designing, brand establishment, and die-cut creation.
                        </motion.p>
                        <motion.p initial={slideAnimation("left").initial} animate={slideAnimation("left").animate} whileInView={slideAnimation("left").whileInView}>
                            My unique background in printing and print theory ensures I understand the <span>requirements</span> for creating artwork that meets both print and personal needs.
                        </motion.p>
                        <motion.p initial={slideAnimation("left").initial} animate={slideAnimation("left").animate} whileInView={slideAnimation("left").whileInView}>
                            You are selecting a <span>dedicated</span> and <span>experienced designer</span> capable of delivering impactful and aesthetically pleasing designs. Let's collaborate to bring your vision to life.
                        </motion.p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ChooseMe