import React from 'react';
import './index.css';
import { springAnimation } from '../../util/JsAnimations';

function ServiceItem(props) {

    if (props.title === undefined) {
        throw new Error('title prop is undefined')
    }
    if (props.iconPath === undefined) {
        throw new Error('icon prop is undefined')
    }
    if (props.link === undefined) {
        throw new Error('link prop is undefined')
    }

    const motion = props.motion;
    const handleClick = ()=>{
        window.location.href = props.collabWithSM ? props.link : `#${props.link}`;
    };

    return (
        <motion.div title={props.collabWithSM ? "In Collaboration With Shekhar Maharaj" : props.title} initial={springAnimation.initial} animate={springAnimation.animate} whileInView={springAnimation.whileInView} exit={springAnimation.exit} className={"service-item"+(props.collabWithSM ? " collab":"")} onClick={handleClick} >
            <h4 className="service-item--title">
                {props.title}
            </h4>
            <img src={props.iconPath} alt={props.title} className="service-item-icon" />
        </motion.div>
    )
}

export default ServiceItem