import React from 'react';
import './index.css';

function Pointer(props) {

    let imgSize = 50;
    imgSize = props.size ? props.size : imgSize;

    return (
        <a className='pointer' href={'#' + props.anchor}>
            <img src="/assets/arrow-down.svg" alt="arrow-down" height={imgSize} width={imgSize} />
        </a>
    )
}

export default Pointer