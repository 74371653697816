import React, { useState, useEffect } from 'react';
import './index.css';

/** @var direction: Responsive values - responsive_VerticalToHorizontal |  responsive_HorizontalToVertical - are run on a mobile first responsive design. That is, the marquee (responsive_VerticalToHorizontal) will first be vertical on mobile, then horizontal on desktop.  */
export default function Marquee({ phrase, direction, repeat }) {

    switch (direction) {
        case "horizontal":
            break;

        case "vertical":

            break;

        case "responsive_VerticalToHorizontal":
            break;

        case "responsive_HorizontalToVertical":
            break;
    
        default:
            throw new Error("Invalid direction");
            break;
    }

    return (
        <div className={`enable-animation ${direction}`}>
            <div className="marquee">
                <ul className="marquee__content">
                    <div className=" marquee__item">
                        {phrase}
                    </div>
                    <div className=" marquee__item">
                        {phrase}
                    </div>
                    <div className=" marquee__item">
                        {phrase}
                    </div>
                    <div className=" marquee__item">
                        {phrase}
                    </div>
                    <div className=" marquee__item">
                        {phrase}
                    </div>
                    <div className=" marquee__item">
                        {phrase}
                    </div>
                </ul>

                <ul aria-hidden="true" className="marquee__content">
                    <div className=" marquee__item">
                        {phrase}
                    </div>
                    <div className=" marquee__item">
                        {phrase}
                    </div>
                    <div className=" marquee__item">
                        {phrase}
                    </div>
                    <div className=" marquee__item">
                        {phrase}
                    </div>
                    <div className=" marquee__item">
                        {phrase}
                    </div>
                    <div className=" marquee__item">
                        {phrase}
                    </div>
                </ul>
            </div>
        </div>
    );
}